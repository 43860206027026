

$main: rgba(255, 216, 49, 0.79);
$background: #292929;
$background_light: linear-gradient(0deg, #EBEBEB, #EBEBEB), linear-gradient(0deg, #FFFFFF, #FFFFFF);
$gray_light: linear-gradient(0deg, #DEE2E6, #DEE2E6), linear-gradient(0deg, #F3F5F7, #F3F5F7);
$gray_dark: #878787;
$dark: #1C212D;
$text_light: #8B96A5;
$text_dark: #1C1C1C;
$white: #ffffff;
$error: #D02B20;
