@import "variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter';
  overflow-x: hidden;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.wrapper {
  display: flex;
  background: $background_light;
  min-height: 100vh;
}

.menu {
  background: $background;
  width: 61px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  transition: width 300ms;
  padding: 24px 0;
  align-items: center;
  display: flex;
  flex-direction: column;


  &.isOpen {
    display: unset;
    width: 240px;

    .subMenuItems {
      position: static;
      width: 100%;
      padding: 2px 0 0 10px;

      li {
        padding: 0;

        a {
          padding-left: 37px;
        }
      }
    }
  }

  .top {
    display: inline-flex;
    width: 100%;
    overflow: hidden;
    margin-bottom: 24px;
    align-items: center;
    padding: 0 18px;
    height: 32px;

    .burger {
      cursor: pointer;
      min-width: 24px;

      path {
        width: 24px;
        height: 24px;
      }
    }

    .logo {
      margin: 0 5px 0 20px;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: $white;
    }
  }

  .menuItems,
  .subMenuItems {
    width: 100%;

    li {
      position: relative;
      padding: 0 3px;

      a, div {
        display: inline-flex;
        align-items: center;
        height: 40px;
        border-radius: 5px;
        padding: 10px 15px;
        width: 100%;
        color: $white;

        span {
          white-space: pre;
        }

        svg:not(.gotoIcon), svg:not(.gotoIcon) path {
          fill: $gray_dark;
          min-width: 24px;
        }

        &.active {
          background: $main;

          svg:not(.gotoIcon), svg:not(.gotoIcon) path {
            fill: $dark;

          }

          .label {
            font-weight: 600;
            color: $dark;

          }
        }

        .sub__menu__arrow {
          position: relative;
          transition: .4s;
          transform: rotateZ(-90deg);
          margin-left: auto;
          min-width: 15px;

          &.open {
            transform: rotateZ(-180deg);
          }
        }
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-left: 13px;
      }
    }
  }

  .subMenuItems {
    position: absolute;
    left: 62px;
    background: #292929;
    width: 240px;
    top: 0;
    padding: 10px 3px;
    border-radius: 5px;

    li {
      a {
        padding: 10px 0
      }
    }
  }
}

.content {
  width: calc(100% - 60px);
  transition: all 300ms;
  margin-left: 60px;
  height: 100%;

  &.open {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
}

.columnHeaders {
  display: flex;
}

.columnHeader {
  display: flex;
  padding: 0 8px;
  height: 57px;
  margin-bottom: 10px;
  width: 237px;
  min-width: 237px;
  border-right: 1px solid transparent;
  position: relative;

  &.fake {
    .title, .price {
      height: 14px;
      background: #EFEFEF;
    }

    .title {
      width: 166px;

    }

    .price {
      width: 66px;
    }
  }

  &:after {
    content: '';
    background-image: url("../icons/arrow.svg");
    height: 28px;
    width: 6px;
    top: 14px;
    right: -4px;
    position: absolute;
  }

  &:last-child:after {
    content: none;
  }

  &:last-child {
    .addTaskContainer .addAutomation,
    .addEmailContainer .addAutomation,
    .addStateContainer .addAutomation {
      right: 8px;
      left: unset;
    }
  }

  .arrow {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: -2px;
  }

  .main {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: $white;
    padding: 9px 12px 8px 16px;
    position: relative;
    justify-content: space-between;
    align-items: center;

    .titleBlock {
      > div {
        display: flex;
        margin-bottom: 3.5px;
      }

      .title, .price, .count {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: #000000;
      }

      .price {
        font-weight: 400;
      }

      .count {
        margin-left: 10px;
        font-size: 13px;
        line-height: 16px;
        background: #E4E6EF;
        border-radius: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        min-width: 25px;
        font-weight: 400;
        padding: 0 4px;
      }
    }

    .border {
      position: absolute;
      height: 2px;
      border-radius: 10px 10px 0 0;
      width: 100%;
      top: 0;
      left: 0;
    }


    .icon {
      cursor: pointer;
    }
  }

  .dropdown {
    width: 220px;
    position: absolute;
    border-radius: 4px;
    padding: 8px;
    background: $white;
    border: 1px solid #00000026;
    top: 68px;
    left: 9px;
    z-index: 2;

    li {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 8px;
      cursor: pointer;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #292929;

      }

      svg {
        margin-right: 8.5px;
        min-width: 18px;
      }
    }
  }
}

.leads {
  padding: 23px 20px 0 16px;
}

.draggableCol {
  display: flex;
}

.dropContainer {
  width: 220px;
  height: 121px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D8DEE3;
  background: #E2E6EE;
}

.droppableColWrapper {
  padding: 0 8px;
  border-right: 1px solid #D8DEE3;
  width: 237px;
  min-width: 237px;

  .fakeButton {
    width: 15px;
    height: 15px;
    background: #EFEFEF;
  }

  &:last-child {
    border-right-color: transparent;
  }

  .addButton {
    width: 100%;
    outline: none;
    background: linear-gradient(0deg, #FBFCFE, #FBFCFE);
    border: 1px solid #E2E6EE;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;

    &.open {
      cursor: not-allowed;

      path {
        fill-opacity: 0.5;
      }

    }
  }
}

.droppableCol {
  width: 100%;
  background: transparent;
  position: relative;
  height: 100%;

  &.draggingOver {
    .dragging {

    }
  }

  .dueDateContainer {
    p {
      color: rgba(0, 0, 0, 0.40);
      font-size: 12px;
      font-weight: 400;

      &.dueDate {
        color: #000000;
        margin-top: 6px;

      }
    }
  }

  .border {
    position: absolute;
    height: 100%;
    width: 1px;
    background: #D8DEE3;
    top: 0;
    left: -8px;
  }
}

.draggable {
  width: 100%;
  border-radius: 5px;
  padding: 10px 17px;
  background: $white;
  border: 1px solid #E2E6EE;
  margin-bottom: 5px;
  min-height: 121px;
  position: relative;

  &.deleted {
    background: #FFF6F6;

    .infoIconContainer {
      margin-bottom: 7px;
    }
  }

  .dotsIcon {
    position: absolute;
    top: 10px;
    right: 8px;
    cursor: pointer;

    svg {
      transform: rotate(90deg);

    }
  }

  .dropdown {
    position: absolute;
    top: 30px;
    right: 16px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

    li {
      cursor: pointer;
      color: #292929;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;

      svg {
        margin-right: 5px;
        width: 18px;
      }
    }
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #356966;
    margin-bottom: 10px;

  }

  .sale {
    color: rgba(0, 0, 0, 0.40);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;

  }

  .task {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #000000CC;
    margin-bottom: 12px;

  }

  .info {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;

    .date {
      display: flex;
      margin-right: 10px;
      align-items: center;

      .icon {
        margin-right: 8px;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: #00000066;

      }
    }

    .taskId {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #00000066;
    }


  }

  .status {
    height: 22px;
    border-radius: 3px;
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    outline: none;
    border: none;

    &.noTasks {
      background: #FF99001A;
      color: #FF9900;
    }

    &.task {
      background: #00AF311A;
      color: #00AF31;

    }
  }
}

.draggableContainer {
  overflow-y: auto;
  padding-top: 17px;
  height: calc(100vh - 200px);
}

.textareaWrapper,
.inputWrapper, {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;


  .currency {
    position: absolute;
    top: 30px;
    left: 13px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    & ~ input {
      padding-left: 22px !important;
    }
  }

  &.h_40 {
    input {
      height: 40px;
    }
  }

  &.error {
    input, textarea {
      border-color: $error;
    }

    .errorText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $error;
      margin-top: 4px;

    }
  }

  label {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #000000;
    margin-bottom: 4px;
  }

  input, textarea {
    background: #F3F5F7;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    padding: 7px 50px 7px 50px;
    border: 1px solid #DDE2E7;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #000000;

    &:read-only {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &:focus {
      border: 1px solid #356966;


    }

    &::placeholder {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #6C757D;
    }

  }

  .leftIcon {
    position: absolute;
    left: 22.5px;
  }

  .rightIcon {
    position: absolute;
    right: 27px;
  }

  textarea {
    padding: 11px 16px 15px 16px;
    min-height: 100px;
    max-width: 100%;
    min-width: 100%;
    font-family: 'Inter';
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 23px 21px 22px 16px;
  align-items: center;
  background: $white;
  border-bottom: 1px solid #EBEBEB;
  width: 100%;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    white-space: nowrap;
  }

  .searchBlock {
    position: relative;
    width: 100%;
    max-width: 535px;
    margin: 0 20px;

    .dropdown {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      padding: 20px 15px;
      border-radius: 5px;
      border: 1px solid #E2E6EE;
      background: #FFF;
      max-height: 400px;
      overflow-y: auto;

      .noData {
        min-height: 80px;
      }

      .title {
        color: #9291A5;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .item {
        color: #000000CC;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 10px;

      }

      .border {
        height: 1px;
        background: rgba(146, 145, 165, 0.46);
        width: 100%;
        margin: 10px 0;
      }

      :last-child {
        .border {
          display: none;
        }
      }
    }

    .leadTitle {
      display: flex;
      align-items: center;
    }

    .contactTitle {
      width: 80px;
      height: 25px;
      background: #DFE6F0;
      padding: 5px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }
  }

  .inputWrapper {
    .leftIcon {
      height: 21px;
      top: 6px;
    }

    .rightIcon {
      height: 18px;
      cursor: pointer;
      top: 9px;

    }
  }

  //.notifications {
  //  height: 40px;
  //  width: 40px;
  //  min-width: 40px;
  //  border-radius: 50%;
  //  background: #F3F6F9;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  position: relative;
  //  cursor: pointer;
  //  margin-right: 13px;
  //
  //  .dot {
  //    position: absolute;
  //    background: #FFD831;
  //    height: 8px;
  //    width: 8px;
  //    top: 7px;
  //    right: 7px;
  //    border-radius: 50%;
  //  }
  //
  //}

  .actions {
    display: flex;
    min-width: 200px;
  }

  .userSettingsDropdown {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 165px;

    .dropdown {
      position: absolute;
      top: 50px;
      right: 0;
      background: $white;
      border: 1px solid #00000026;
      width: 137px;
      padding: 10px;
      border-radius: 5px;
      min-width: 165px;

      li {
        width: 100%;

        a {
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #292929;
          width: 100%;
          display: flex;
        }
      }
    }

    .open {
      transform: rotate(180deg);
    }

    .select {
      display: flex;
      align-items: center;
      margin-left: 8px;
      cursor: pointer;

      svg {
        transition: all 0.3ms;

      }

      p {
        margin-right: 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
      }
    }
  }
}

.headerLogout {
  height: 80px;
  width: 100%;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 20px;

  .logo {
    display: flex;
    align-items: center;

    svg {
      height: 42px;
      width: 41px;
      margin-right: 9px;
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      color: #000000;

    }
  }

  .actions {
    display: flex;

    a {
      height: 36px;
      width: 85px;
      font-size: 14px;
    }

    .outlined {
      margin-right: 10px;
    }

  }
}

.login {
  background: $background_light;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;

  .forgotPassBlock, .goBack {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #356966;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }

  form {
    height: 421px;
    width: 456px;
    border-radius: 10px;
    background: $white;
    padding: 36px 59px 47px 59px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .inputWrapper {
      margin-bottom: 20px;

      input {
        padding: 10px 16px;
      }

      .rightIcon {
        cursor: pointer;
        top: 28px;
        right: 14px;
      }

      .text {

        path {
          fill: $white;

        }
      }
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
      color: $background;
      margin-bottom: 4px;
    }

    .desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #849291;
      margin-bottom: 30px;

    }
  }
}

.mainButton {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background: #FFD831;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #1C212D;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  box-shadow: 0 0 5px rgba(41, 41, 41, 0);
  transition: box-shadow 300ms;

  &:hover {
    box-shadow: 0 0 5px rgba(41, 41, 41, 0.6);
  }

  &.outlined {
    background: $white;
    border: 1px solid #FFD831;
  }

  &.noBorder {
    border: none;
    background: $white;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

}

.checkboxWrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #32324D;

  &.error {
    .checkmark {
      border-color: $error;
    }
  }
}

.checkboxWrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid #C0C0CF;
  background: $white;

}


.checkboxWrapper {
  &.indeterminate {
    .checkmark {
      border-color: #afafaf;
      background: #afafaf;

      &:after {
        display: block;
        top: 2px;
        left: 5.8px;
        border: solid #FFFFFF;
        border-width: 0 3px 0 0;
        transform: rotateZ(90deg);
      }
    }
  }

  &.greenBG {
    input:checked ~ .checkmark {
      border-color: #356966;
      background: #356966;

      &:after {
        border: solid #FFFFFF;
        border-width: 0 2px 2px 0;
        transform: rotateZ(45deg);
      }
    }

    input:disabled ~ .checkmark {
      border-color: #686868;
      background: #686868;
      cursor: auto;
    }
  }

  input:checked ~ .checkmark {
    background: $white;
  }
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxWrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkboxWrapper .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.loader {
  border: 4px solid var(--loader-border-bg-color);
  border-radius: 50%;
  -webkit-animation: spCircRot .6s infinite linear;
  animation: spCircRot .6s infinite linear;
  width: 25px;
  height: 25px;
  clear: both;
  margin: 20px auto;
}

@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;

  ~ * {
    z-index: 9999;
  }
}

.addLead {
  padding: 10px 10px 14px 10px;
  border: 1px solid #E2E6EE;
  background: $white;
  border-radius: 5px;

  .inputWrapper, .selectWrapper, .textareaWrapper {
    margin-bottom: 5px;

    .fus__control {
      background: transparent;
    }

    .fus__menu:has(.fus__menu-notice) {
      display: none !important;
    }

    input {
      background: transparent;
      padding: 5px 9px;
      font-size: 13px;
    }

    .fus__placeholder, .fus__single-value {
      padding: 0;
    }

    textarea {
      background: $white;
      padding: 5px 9px;
    }
  }

  .actions {
    display: flex;

    button {
      width: 56px;
      height: 36px;
      font-size: 12px;

      &.noBorder {
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }
}

.tabs {
  display: flex;
  border-bottom: 1px solid #DEE2E7;
  padding: 23px 20px 11px 20px;
  width: 100%;
  justify-content: space-between;
  height: 70px;

  .nav {
    a {
      min-width: 120px;
      text-align: center;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: #8B96A5;

      &.active {
        border-bottom: 2px solid #FFD831;
        color: #1C212D;

      }

    }
  }

  .actions {
    display: flex;
    align-items: center;

    .settings {
      background: $white;
      border: 1px solid #DBDBDB;
      height: 38px;
      border-radius: 5px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 9px 10px;

      p {
        color: #849291;
        font-size: 12px;
        font-weight: 600;
        margin-left: 6px;
      }

      &:hover {
        box-shadow: 0 0 5px rgba(41, 41, 41, 0.6);
      }
    }

    .mainButton {
      padding: 7px 13px;
      width: 104px;
      height: 38px;
      margin-left: 10px;

      span {
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 10px;

        path {
          fill: #1C212D;

        }
      }

      &.noBorder {
        background: transparent;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        width: 90px;
      }
    }

  }
}

.selectWrapper {
  .label {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #000000;

    + div {
      margin-top: 2px;
    }
  }

  &.error {
    .fus__control {
      border-color: $error;
    }

    .errorText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $error;
      margin-top: 4px;

    }
  }


  .fusIndicatorsContainer {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 17px 0 5px;

    > span {
      display: flex;
    }

    .loader {
      margin: 0 -5px 0 0;
    }
  }

  .fus__control {
    min-height: 38px;
    outline: none;
    box-shadow: none;
    background: linear-gradient(0deg, #F3F6F9, #F3F6F9),
    linear-gradient(0deg, #DCDCE4, #DCDCE4);
    cursor: pointer;
    border: 1px solid #DCDCE4;

  }

  .fus__control--is-focused {
    border: 1px solid #356966;
    background: $white;
    //min-height: 36px;

    &:hover {
      border: 1px solid #356966;

    }
  }

  .fus__clear-indicator {
    padding: 8px 0;
  }

  .fus__single-value, .fus__placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    padding: 0 8px;

  }
}

body {
  .fus__option--is-focused {
    background: #F3F5F7;
  }

  .fus__option--is-selected {
    background: rgba(#356966, 0.5);
    color: $white;
  }

  .fus__option {
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &:active {
      background: #356966;
    }

  }

  .fus__placeholder {
    color: #00000080;

  }

  .fus__option--is-disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .fus__menu-list {
    padding: 4px;
  }
}


.addTaskModal, .addPipelineModal {
  .inputWrapper {
    input {
      padding: 11px 16px;
    }
  }

  .elements {
    display: flex;
    justify-content: space-between;

    .selectWrapper {
      width: 49%;
    }
  }

  .selectWrapper, .textareaWrapper, .date__picker__wrapper {
    margin-bottom: 20px;
  }

  .selectSmall {
    display: flex;
    justify-content: space-between;

    .selectWrapper {
      width: 49%;
      margin-bottom: 10px;

    }

    .inputs {
      display: flex;
      justify-content: space-between;
      width: 49%;
      align-items: flex-end;

      .inputWrapper {
        width: 49%;
        margin-bottom: 10px;

        input {
          height: 38px;
        }
      }
    }
  }

  .actions {
    display: flex;

    .dropdownIndicator {
      width: 35px;
      height: 100%;
      border-radius: 0 4px 4px 0;
      background: #FFD831;
      margin-left: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .createButtonContainer {
      .dropdown {
        top: 45px;
      }
    }

    button {
      width: 124px;

      &.noBorder {
        width: 60px;
        font-weight: 400;
        font-size: 12px;
        margin-left: 10px;

      }

      &.create {
        border-radius: 4px 0 0 4px;
        box-shadow: unset;
      }
    }
  }

  .customDate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .inputWrapper {

      margin-right: 10px;

      input {
        padding: 10px 16px;
        height: 40px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }

  .delete {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #E40000;
    margin-bottom: 20px;
    cursor: pointer;
  }
}

.createLead {
  display: flex;
  background: $background_light;

  .left {
    background: $white;
    padding: 30px 5px 30px 20px;
    min-height: calc(100vh - 86px);
    width: 30%;

    .autocompleteInputWrapper {
      .rightIcon {
        top: -10px;
      }

      ul {
        top: 33px;
      }
    }

    .title {
      margin-bottom: 20px;
      color: #000;
      font-size: 18px;
      font-weight: 500;
    }

    .inputWrapper, .selectWrapper {
      margin-bottom: 10px;

    }

    .inputWrapper {
      input {
        padding: 10px;

        &:focus {
          border: 1px solid #DBDBDB;
        }
      }
    }

    .selectWrapper {

      .fus__value-container {
        padding: 2px 8px 2px 0;
      }

      .fus__single-value {
        border-radius: 4px;
        background: #DFE6F0;
        max-width: min-content;
        height: 20px;
        display: flex;
        align-items: center;
      }

      .fus__option, .fus__single-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fus__control--is-focused {
        border: 1px solid #DBDBDB;

        &:hover {
          border: 1px solid #DBDBDB;

        }

      }
    }
  }

  .right {
    padding: 19px 16px 30px 16px;
    width: 70%;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .feeActions {
        display: flex;
        align-items: center;

        .date__picker__wrapper {
          width: 141px;
        }

        .checkboxWrapper {
          margin-bottom: 0;
          width: unset;
          margin-right: 10px;
          color: #292929;
          font-size: 13px;
          font-weight: 500;
          padding-left: 28px;
        }
      }

      > div {
        display: flex;
        align-items: center;
      }

      .actions {


        button {
          font-size: 14px;
          font-weight: 600;
          height: 36px;
          width: 78px;

          &.noBorder {
            font-weight: 400;
            font-size: 12px;
            background: transparent;
            margin-right: 5px;
          }

          &.create {
            border-radius: 4px 0 0 4px;
            box-shadow: unset;
          }
        }

        .dropdownIndicator {
          width: 35px;
          height: 36px;
          border-radius: 0 4px 4px 0;
          background: #FFD831;
          margin-left: 1px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      .new {
        height: 20px;
        width: 42px;
        border-radius: 99px;
        padding: 4px 8px 4px 8px;
        background: #356966;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        color: $white;
        margin-right: 16.5px;

      }

      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #000000;

      }
    }

    .main {
      background: $white;
      padding: 20px 32px 20px 16px;
      border-radius: 5px;
      min-height: 500px;
      margin-top: 26px;

      textarea {
        background: transparent;

      }
    }
  }


}

.createButtonContainer {
  box-shadow: 0 0 5px rgba(41, 41, 41, 0);
  transition: box-shadow 300ms;
  display: flex;
  border-radius: 4px;
  position: relative;

  &:hover {
    box-shadow: 0 0 5px rgba(41, 41, 41, 0.6);
  }

  .dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    padding: 13px 19px;
    color: #356966;
    font-size: 13px;
    font-weight: 500;
    width: 133px;
    height: 42px;
    cursor: pointer;
  }
}

.createLeadInfoWrapper {
  .details {
    .table__wrapper {
      min-height: unset;
    }
  }

  .tabs {
    justify-content: unset;
    padding: 0;
    height: unset;

    p {
      min-width: 80px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      padding: 17px 15px;
      display: flex;
      justify-content: center;

      &.active {
        border-bottom: 1px solid #FFD831;
      }

      &.error::after {
        content: '';
        height: 5px;
        width: 5px;
        background: #D02B20;
        border-radius: 50%;
      }
    }
  }
}

.columnsDrag {
  margin-top: 17px;
  overflow-x: auto;

  .colContainer {
    height: calc(100vh - 263px);
    background: linear-gradient(0deg, #EBEBEB, #EBEBEB), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .delete {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #EA4335;

    }
  }

  .colData {
    min-width: 100%;
    padding: 21px 8px;
    border-right: 1px solid #D8DEE3;
    width: 100%;
    margin-top: 10px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .inputWrapper {
      margin-bottom: 20px;

      input {
        background: $white;
        padding: 10px 16px;
        height: 40px;
      }
    }

    .selectWrapper {
      .fus__control {
        height: 40px;
        background: $white;
      }
    }

  }

  .addButton {
    position: absolute;
    z-index: 11;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #D0D0D0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 223px;
    top: 13px;
  }

  .cols {
    z-index: 12;

    display: flex;
    width: 100%;
    overflow-x: auto;
  }

  .col {

    padding: 0 8px;
    width: 237px;
    background: linear-gradient(0deg, #EBEBEB, #EBEBEB), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    position: relative;

  }

  .main {
    z-index: 10;

    height: 57px;
    min-width: 100%;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px 0 #0000000D;
    display: flex;
    justify-content: space-between;
    padding: 20px 12px 20px 16px;
    position: relative;
    margin-right: 16px;

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color: #000000;

    }


    .border {
      position: absolute;
      height: 2px;
      border-radius: 10px 10px 0 0;
      width: 100%;
      top: 0;
      left: 0;
      background: #BAC0C7;

    }
  }
}

.activity {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;


  .noActivity {
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #00000080;
      margin-top: 22px;
    }
  }
}

.addPipelineModal {
  .actions {
    button {
      width: 150px;
    }
  }
}

.leadsList {
  border: 1px solid #EAEAEA;
  background: $white;
  margin-top: 13px;

  .phoneIcon {
    margin-right: 15px;
    cursor: pointer;

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed !important;
    }
  }

}

.notes {
  padding: 24px 16px;

  .editor {
    width: 100%;
  }

  .editorBlock {
    position: relative;

    .editor {

      .ql-editor {
        padding: 12px 15px 45px 15px;
      }
    }

    .mainButton {
      position: absolute;
      right: 8px;
      bottom: 8px;
      height: 32px;
      width: 114px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: $white;
      background: #356966;

    }
  }


}

.notesBlock {
  margin: 20px 0;

  .note {
    display: flex;
    padding: 5px;

    &.editing {
      background: #F8F9FA;
      border-radius: 5px;
    }

    .img {
      margin-right: 25px;
    }

    .info {
      width: 90%;

      .top {
        display: flex;
        width: 100%;
        align-items: center;
        height: 25px;

        .name {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }

        .dot {
          height: 4px;
          width: 4px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #00000080;
          margin: 0 10px;
        }

        .date {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #00000080;

        }

        div {
          display: flex;
          justify-content: space-between;
        }

        .actions {
          .icon {
            cursor: pointer;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            &:hover {
              background: #F3F6F9;

              svg {
                path {
                  fill: #356966;

                }
              }
            }
          }
        }
      }

      .text {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #00000080;
        padding: 10px 20px 10px 0;

      }
    }
  }
}

.leadsTask {
  padding: 20px 16px;

  .mainButton {
    height: 40px;
    width: 106px;
    border-radius: 4px;
    background: #356966;
    font-size: 14px;
    line-height: 16px;
    color: $white;
  }

  .tasksList {
    margin: 20px 0;

    .leftBlock {
      display: flex;

      .col {
        min-width: 200px;
      }
    }

    .task {
      border: 1px solid #E2E6EE;
      padding: 20px 15px;
      margin-bottom: 10px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .delete {
        cursor: pointer;
      }

      .col {
        > div {
          display: flex;
          margin-bottom: 7px;

          .label {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            color: #292929;
            margin-right: 9px;
          }

          .value {
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            color: #000000CC;


          }

        }
      }
    }
  }
}

.editor {
  .quill {
    min-height: 178px;
    border-radius: 4px;
    border: 1px solid #DCDCE4;
  }

  &.error {
    .quill {
      border-color: $error;
    }
  }

  .errorText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $error;
    margin-top: 4px;

  }

  .ql-editor {
    background: linear-gradient(0deg, #F3F6F9, #F3F6F9),
    linear-gradient(0deg, #DCDCE4, #DCDCE4);
    min-height: 143px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

  }

  .ql-container {
    height: 100%;
    border: none;

  }

  .ql-toolbar.ql-snow {
    border: none;
  }
}

.leadsEmail, .leadsTask {
  padding: 20px 16px;


  .inputWrapper, .selectWrapper, .textareaWrapper, .date__picker__wrapper {
    margin-bottom: 20px;

    input {
      padding: 10px 16px;
    }

    textarea {
      background: linear-gradient(0deg, #F3F6F9, #F3F6F9), linear-gradient(0deg, #DCDCE4, #DCDCE4) !important;
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
  }

  .mainButton:not(.outlined) {
    margin-top: 20px;
    background: #356966;
    color: $white;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
    height: 30px;
  }

  .outlined {
    width: 200px;
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.addNewLineButton {
  color: #356966;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  svg {
    margin-right: 5px;

    path {
      fill: #356966;
    }
  }
}

.deleteIcon {
  top: 10px;
  right: -30px;
  background: #849291;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;

  svg {
    width: 7px;
    height: 7px;
    margin: 0;

    path {
      fill: $white;
    }
  }
}

.addTaskContainer, .addEmailContainer, .addStateContainer {
  .contentContainer {
    overflow: auto;
    max-height: 540px;
    padding: 15px 52px 19px 18px;
  }


  .addAutomation {
    width: 485px;
    border-radius: 5px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
    linear-gradient(0deg, #E2E6EE, #E2E6EE);
    position: absolute;
    left: 7px;
    z-index: 9999;
    top: 66px;
    border: 1px solid #E2E6EE;
  }

  .editor {
    margin-bottom: 20px;

    //.ql-editor {
    //  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
    //  linear-gradient(0deg, #E2E6EE, #E2E6EE);
    //}

  }

  .inputWrapper {
    margin-bottom: 20px;

    input {
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
      linear-gradient(0deg, #E2E6EE, #E2E6EE);
      padding: 7px 10px;
    }

  }

  .selectWrapper {
    margin-bottom: 20px;

    .fus__control {
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
      linear-gradient(0deg, #E2E6EE, #E2E6EE);
    }
  }

  .date__picker__wrapper {
    margin-bottom: 20px;

    input {
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
      linear-gradient(0deg, #E2E6EE, #E2E6EE) !important;
      border: 1px solid #DCDCE4 !important;
    }
  }

  .textareaWrapper {
    margin-bottom: 10px;

    textarea {
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
      linear-gradient(0deg, #E2E6EE, #E2E6EE);
    }
  }

  .inputs {
    display: flex;
    justify-content: space-between;

    .inputWrapper {
      width: 49%;

    }

    input {
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
      linear-gradient(0deg, #E2E6EE, #E2E6EE);
      padding: 7px 10px;
    }
  }

  .actions {
    display: flex;
    position: sticky;
    bottom: -20px;
    padding: 10px 0 20px;
    width: 100%;
    background: white;
    border-top: 1px solid #D9D9D9;

    button {
      width: 124px;
      height: 36px;
      font-size: 14px;

      &.noBorder {
        font-weight: 400;
        font-size: 12px;
        width: 80px;
      }
    }
  }
}

.executeDropdown {
  position: absolute;

  .saveButton {
    background: #F3F6F9;
    margin-bottom: 10px;

    p {
      color: #356966;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      padding: 8px 16px;
      cursor: pointer;

      &.disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
  }

  .dropdown {
    max-height: 400px;
    overflow-y: auto;
    width: 405px;
    border-radius: 4px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
    linear-gradient(0deg, #EAEAEF, #EAEAEF);
    position: absolute;
    border: 0.5px solid #EAEAEF;
    box-shadow: 0 1px 4px 0 #2121341A;
    z-index: 99999;
    top: -10px;
    left: 0;

    &.top {
      top: -471px;
    }
  }

  .item {
    .datepicker {
      padding: 16px;
      display: flex;
      align-items: center;

      .date__picker__wrapper {
        margin-right: 10px;
      }
    }

    > div {
      background: #849291;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 600;
      line-height: 13px;
      color: $white;
      text-transform: uppercase;

      &.first {
        border-radius: 4px 4px 0 0;
      }
    }

    ul {
      padding: 8px 0;
    }

    li {
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #292929;
      cursor: pointer;

      &.active {
        background: rgba(53, 105, 102, 0.4);
        color: $white;
      }
    }
  }
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #E2E6EE transparent;
  position: absolute;
  top: -8px;
  left: 10px;
}

.conditions {
  .border {
    height: 1px;
    width: 100%;
    background: #D9D9D9;
    margin-bottom: 20px;

  }

  .delete {
    position: absolute;
    top: 30px;
    right: -30px;
    background: #849291;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    cursor: pointer;

    svg {
      width: 7px;
      height: 7px;
      margin: 0;

      path {
        fill: $white;
      }
    }

  }

  .addConditionButton {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #356966;
    margin-bottom: 20px;
    cursor: pointer;

    svg {
      margin-right: 6px;

      path {
        fill: #356966;
      }

    }
  }

  .selectWrapper {
    .label {
      min-height: 16px;
    }
  }

  .row {
    position: relative;
  }

  .query {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 100%;

    }

    .small {
      width: 69%;
    }

    .right {
      width: 30%;
    }


  }
}

.colorPicker {
  position: relative;

  .inputWrapper {
    input {
      caret-color: transparent;
    }
  }

  .picker {
    position: absolute;
    z-index: 9999;
  }
}

.details {
  padding: 30px 16px;

  .info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DEE2E6;
    padding-bottom: 20px;

    > div {
      width: 49%;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #000000;
    margin-bottom: 20px;
  }

  .leftBlock {

    .infoCols {
      display: flex;

      .col {
        margin-right: 85px;
        max-width: 130px;
      }

      .label {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: #000000;
        margin-bottom: 5px;
      }

      .value {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #292929;
        margin-bottom: 20px;
      }

    }
  }

  .fileRow {
    display: flex;
    background: #F8F9FA;
    width: 274px;
    padding: 4px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    > div {
      display: flex;
      align-items: center;

      a, p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #356966;
        margin-left: 5px;
      }

    }

    .delete {
      cursor: pointer;
    }
  }

  min-width: 280px;

  .mainButton {
    width: 147px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #356966;
    color: #356966;
  }


  .additionalExpenses {
    margin-top: 40px;
    margin-bottom: 20px;

    .table {
      background: #F3F6F9;

    }

    table, thead, tbody, tr, td, th {
      border: none;

    }

    table {
      border-collapse: collapse;
      width: 100%;

      .errorText {
        position: absolute;
        bottom: -16px;
      }

      thead {
        background: #849291;
        height: 54px;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        th {
          padding-left: 25px;
          text-align: start;
        }
      }

      tbody {
        tr {
          padding: 21px 25px;
        }

        td {
          text-align: center;
          color: #000;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }

        .total, .remove {
          padding-top: 15px;

        }

        .remove {
          > div {
            background: #849291;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            cursor: pointer;

            svg {
              width: 6px;

              path {
                fill: $white;
              }
            }
          }
        }
      }

    }

    .inputWrapper {
      padding: 21px 0 0 25px;

      input {
        height: 40px;
        padding: 7px 16px;
        background: $white;
      }
    }

    .add {
      cursor: pointer;
      width: 200px;
      display: flex;
      align-items: center;
      padding: 21px 25px;

      svg {
        margin-right: 5px;

        path {
          fill: #356966;
        }

      }

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: #356966;

      }
    }

    .mainButton {
      width: 96px;
      height: 36px;
      background: #356966;
      color: $white;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;


    }

    .profit {
      height: 54px;
      border-radius: 10px;
      background: #F3F6F9;
      margin-top: 30px;
      padding: 10px 20px;
      display: flex;
      align-items: center;

      .label {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #292929;
        margin-right: 10px;
      }

      .value {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        color: #356966;

      }

    }
  }


}

.fileInput {
  position: relative;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;

  svg {
    margin-right: 5px;
  }

  .file {
    display: flex;
    cursor: pointer;
    width: 90px;
    height: 27px;
    border-radius: 3px;
    border: 1px solid #356966;
    padding: 5px 9px;

    input {
      visibility: hidden;
      width: 100%;
      height: 100%;
      display: none;
    }
  }

  .uploadFile {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #356966;
    display: flex;
  }
}

.activityWithInfo {
  padding: 40px 20px 20px;
  position: relative;

  .notesBlock {
    //border-top: 1px solid $text_light;
    max-height: 400px;
    overflow-y: auto;
    margin-top: 0;
  }

  .notesBlockLabel {
    display: flex;
    align-items: center;
    color: #292929;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 20px;
    line-height: 1;

    &:after {
      content: '';
      width: 50%;
      height: 1px;
      background: #DEE2E6;
      display: block;
      margin-left: 24px;
    }

    &:before {
      content: '';
      width: 50%;
      height: 1px;
      background: #DEE2E6;
      display: block;
      margin-right: 24px;
    }
  }

  .notes {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: sticky;
    bottom: 0;
    background: white;
    width: calc(100% + 40px);
    padding: 0 20px 20px;
    margin: 0 -20px;

    .mainButton {
      background: #356966;
      color: $white;
      font-size: 12px;
      font-weight: 600;
      width: 100px;
      height: 30px;
      position: absolute;
      bottom: 26px;
      right: 33px;
    }

    .editor .quill {
      min-height: unset;
      display: flex;
      flex-direction: column;

      .ql-toolbar {
        order: 1;
        padding-right: 125px;

        .ql-picker-options {
          transform: translate(0, -100%);
          top: 0;
        }
      }
    }

    .editor .ql-editor {
      min-height: 80px;
    }
  }

  .activityContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-height: 30vh;
    overflow-y: auto;


    .seeMore {
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #DCDCE4;
      color: #32324D;
      cursor: pointer;
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      background-image: url("../icons/line.svg");
      top: 62px;
      left: 4px;
      position: absolute;
      z-index: 10;
      height: 100%;
      overflow: hidden;
      width: 1px;
      background-repeat: repeat-y;

    }

    .items {
      width: 100%;

    }

  }


  .groupDate {
    width: 84px;
    height: 25px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #000000;
    background: #F3F3F3;
    margin-bottom: 23px;
    padding: 2px 5px;
  }

  .activityItem {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #356966;
      margin-right: 20px;
      z-index: 20;

    }

    .date {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #849291;
      margin-bottom: 5px;
    }

    .action {
      color: #292929;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .bold {
      font-weight: 600;
      margin-left: 5px;
    }
  }

}

.incomingCall {
  width: 193px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #356966;
  margin-bottom: 18px;
  padding: 11px 20px 14px 20px;


  .info {
    margin-bottom: 10px;

    p {
      color: #FFF;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .callHeader {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    svg {
      width: 14px;

      path {
        fill: $white;
      }
    }

    p {
      color: $white;
      font-size: 14px;
      font-weight: 600;
      margin-left: 5px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.27);
      cursor: pointer;
      margin-right: 6px;
      background: #00AF31;

      svg {
        path {
          fill: $white;
        }
      }

      &.endCall {
        margin-right: 0;
        background: #F40000;

        svg {
          transform: rotate(135deg);


        }
      }
    }
  }


  &.isContact {
    background: #00AF31;

    .answer {
      background: #356966;
    }
  }
}

.menu {
  .call {
    .incoming {
      position: absolute;
      bottom: 90px;
      left: 18px;

      .addLeadButton {
        margin-top: 10px;
      }

      &.multi {
        left: unset;
        right: 20px;
        bottom: 60px;
      }
    }

    .multiCalls {
      cursor: pointer;

      span {
        position: absolute;
        right: 0;
        top: 0;
        width: 23px;
        height: 23px;
        background: #D00;
        border-radius: 50%;
        color: #FFFFFF;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        padding-top: 3px;
      }
    }

  }

  .answered {
    position: absolute;
    bottom: 0;
    left: 350px;
  }
}

.callModal {
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.modal__wrapper {
    width: 412px !important;

  }

  input {
    height: 40px;
    width: 380px;
    padding: 10px 40px;
  }

  .leftIcon {
    left: 16px;
    top: 31px;
  }

  .title {
    color: #292929;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;

    .mainButton {
      width: 186px;
      height: 36px;
      border: 1px solid #DCDCE4;
      background: $white;

      > div {
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 8px;
      }

      &.call {
        background: #F3F6F9;
        color: #356966;
        margin-left: 8px;
      }
    }
  }
}

.callDetails {
  width: 407px;
  height: 83px;
  border-radius: 10px 10px 0 0;
  padding: 11px 16px;
  background: #356966;
  position: absolute;
  bottom: 0;
  left: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .infoContainer {
    display: flex;
    align-items: center;
  }


  .info {

    margin-left: 11px;


    .name {
      color: #FFF;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .duration {
      color: #FFF;
      font-size: 12px;
      font-weight: 400;
    }

  }

  .actions {
    display: flex;


    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.27);
      cursor: pointer;
      margin-right: 6px;

      &.endCall {
        margin-right: 0;
        background: #F40000;

        svg {
          transform: rotate(135deg);

          path {
            fill: $white;
          }
        }
      }

      &.ringing {
        cursor: not-allowed;
      }
    }

  }

}

.gotoIcon {
  margin-left: 13px;
}

.goTo {
  padding: 40px 16px;

  .mainButton {
    width: 150px;
    font-size: 12px;

  }

  .have {
    color: rgba(0, 0, 0, 0.80);
    font-size: 14px;
    font-weight: 600;
  }
}

.calls {
  padding: 48px 32px 48px 16px;

  .item {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 30px;
    max-height: 50px;

    > div {
      display: flex;
      justify-content: space-between;
    }

    .info {
      width: 93%;
    }

    .phoneIcon {
      width: 37px;
      height: 37px;
      background: #356966;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      svg {
        path {
          fill: $white;
        }
      }

      &:after {
        content: '';
        background-image: url("../icons/line.svg");
        top: 37px;
        left: 17px;
        position: absolute;
        z-index: 10;
        height: calc(100% - 8px);
        overflow: hidden;
        width: 1px;
        background-repeat: repeat-y;
      }

    }

    &:last-child {
      .phoneIcon:after {
        display: none;
      }
    }

    .reachedNumber {
      display: flex;

      .phone {
        margin-right: 5px;
        background: #F3F6F9;
        border-radius: 100px;
        padding: 5px 8px;
      }
    }

    .callerInfo {
      color: #292929;

      > div {
        display: flex;
        margin-bottom: 6px;
        align-items: center;
      }

      .name {
        font-size: 14px;
        font-weight: 600;
        margin-right: 11px;
      }

      .phone {
        font-size: 14px;
        font-weight: 400;
        color: #849291;
      }

      .number {
        font-size: 14px;
        font-weight: 400;
        margin-right: 11px;

      }
    }

    .callInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .date {
        color: #292929;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: flex-end;
      }

      .durationInfo {
        display: flex;
        align-items: center;

        .duration {
          p {
            color: #849291;
            font-size: 12px;
            font-weight: 400;
          }

          :first-child {
            margin-right: 5px;
          }
        }

        .type {
          margin-right: 10px;
          align-items: center;

          svg {
            margin-right: 2px;
          }

          span {
            color: #849291;
            font-size: 12px;
            font-weight: 400;
          }
        }

        > div {
          display: flex;
        }
      }
    }
  }
}

.timePickerWrapper {
  display: flex;
  flex-direction: column;

  .timePicker {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #DDE2E7;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 20px;
    padding: 10px;

  }

  .label {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #000000;
    margin-bottom: 4px;
  }


  .react-datepicker--time-only {
    .react-datepicker__header {
      background: rgba(53, 105, 102, 0.4);
    }

    .react-datepicker-time__header {
      color: $white;

    }

    .react-datepicker__time-list-item--selected {
      background-color: rgba(53, 105, 102, 0.4) !important;

    }

  }
}

.automationsList {
  padding: 23px 20px 0 16px;

  .columnHeader:after {
    background-image: none;
  }

  .columnsDrag {
    display: flex;

    .icon {
      cursor: pointer;
    }
  }

  .colData {
    margin-top: 0;
    padding: 0 8px;
    justify-content: unset;
  }

  .automationItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #E2E6EE;
    background: #FFF;
    margin-bottom: 10px;

    .colorContainer {
      display: flex;
      align-items: center;
    }

    .color {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      display: flex;
      margin-left: 5px;
    }

    .info {
      width: 91%;

      p {
        color: #292929;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 5px;
      }

      .bold {
        font-weight: 600;
      }
    }

    .actions {
      width: 15%;
      display: flex;
      height: 20px;
      align-items: center;

      svg {
        width: 12px;
        cursor: pointer;
        margin-left: 3px;
      }
    }
  }
}

span.readOnly {
  cursor: not-allowed;

  > *,
  a {
    opacity: 0.7;
    filter: grayscale(50%);
    cursor: not-allowed !important;

    * {
      cursor: not-allowed !important;
    }
  }
}

.googleAuth {
  padding: 40px 16px;

  .mainButton {
    width: 200px;
  }

  .connectionButton {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .info {
    display: flex;
    margin-top: 30px;
    background: $white;
    padding: 20px 30px 40px;
    border-radius: 5px;

    input {
      padding: 10px;
    }

    :first-child {
      margin-right: 10px;
    }
  }
}

.filterUsers {
  .selectWrapper {
    margin-right: 30px;
    min-width: 170px;
    height: 40px;

    .fus__control {
      height: 40px;
    }
  }
}

.autocompleteInputWrapper {
  position: relative;

  .rightIcon {
    top: 7px;
  }

  input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ul {
    position: absolute;
    width: 100%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    top: 60px;
    left: 0;

    li {
      color: #000000;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding: 8px 12px;
    }
  }
}

.addNotificationTemplate {
  .email__template__left__block {
    width: 100% !important;
  }
}

.addNotificationSettings {
  .selectWrapper {
    margin-bottom: 20px;
  }

  .item {
    position: relative;

    .deleteIcon {
      right: -7px;
    }

    .selectWrapper {
      margin-right: 20px;
    }

    .options {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .selectWrapper {
        width: 100%;
      }
    }

    .border {
      background: #D9D9D9;
      height: 1px;
      width: 100%;
      margin-bottom: 20px;
    }

    &:last-child {
      .border {
        display: none;
      }
    }
  }

  .checkboxWrapper {
    input:checked ~ .checkmark {
      background: #FFD831;
    }
  }

  .actions {
    margin-top: 20px;
    display: flex;

    .mainButton {
      width: 130px;
      font-size: 14px;

      &.noBorder {
        width: 90px;
        margin-left: 10px;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}

.notificationSettings {
  .mainButton {
    &.edit {
      background: transparent;
      border: 1px solid #849291;
      color: #849291;

      svg {
        path {
          fill: #849291 !important;
        }
      }

    }
  }

  .notificationSettingsContent {
    background: $white;
    min-height: calc(100vh - 197px);
    padding: 45px 0;

    .row {
      margin: 20px 0 40px 0;
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 0 7.5% 0 40px;

      .border {
        position: absolute;
        height: 1px;
        width: 100%;
        left: 0;
        background: #EAEAEA;
        bottom: -20px;
      }

      .inputWrapper {
        margin-right: 16px;

        input {
          padding: 10px 16px;
        }
      }

      .category {
        color: #292929;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        margin-right: 17px;
        margin-top: 15px;
      }

      .when {
        color: #849291;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 16px;
        margin-top: 15px;

      }
    }
  }

  .checkboxWrapper {
    cursor: not-allowed;
    margin-bottom: 0;
    margin-top: 20px;
    width: 200px;

    input:checked ~ .checkmark {
      background: #FFD831;
    }
  }
}

.callReports {
  margin: 12px 16px;

  .callType {
    display: flex;

    > span {
      margin-right: 5px;

      .out {
        path {
          fill: #E40000;
        }
      }

      .in {
        path {
          fill: #00C236;
        }
      }
    }
  }

  .createLeadButton {
    display: flex;
    align-items: center;

    span {
      color: #356966;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }

    svg {
      margin-right: 5px;

      path {
        fill: #356966;
      }
    }
  }
}

.archive {
  margin: 13px 16px;

  .typeSelect {
    padding: 20px 10px 30px;
    display: flex;
    justify-content: flex-end;

    .selectWrapper {
      width: 250px;
    }
  }
}

.addAutomationContainer {
  .inputWrapper {
    input {
      padding: 7px 12px !important;
    }
  }

  input::placeholder {
    color: #000000;
    padding: 12px 7px;
  }

  .actions {
    .noBorder {
      margin-left: 5px;
    }
  }
}

.createLead {
  input::placeholder {
    color: #000000;
  }
}

.unsubscribeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 100px);
}

.unsubscribe {
  width: 412px;
  background: #FFFFFF;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3, .inputWrapper {
    margin-bottom: 20px;
  }

  input {
    padding: 10px 7px;
  }
}

.usersList {
  .actions__wrapper {
    display: flex;
    align-items: center;
  }
}

.rc-tooltip {
  opacity: 1 !important;
  background: transparent !important;

}

.rc-tooltip-arrow {
  border-top-color: #292929 !important;
  margin-left: 0 !important;
}

.rc-tooltip-content {
  .rc-tooltip-inner {
    background: #292929;
    border: unset;
    border-radius: 10px;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
  }
}

.emails {
  display: flex;
  align-items: center;
  justify-content: center;

  .list {
    width: 100%;
  }

  .emailText {
    color: #292929;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    margin-top: 6px;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;

  }

  .groupDateContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .groupDate {
    width: 84px;
    height: 25px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #000000;
    background: #F3F3F3;
    margin-bottom: 23px;
    padding: 2px 5px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }

  .date {
    color: #849291;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .info {
    color: #292929;
    font-size: 14px;
    font-weight: 500;

    .from {
      text-decoration-line: underline;

    }
  }

  .view {
    color: #356966;
    font-size: 14px;
    font-weight: 500;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.emailHtmlContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > div {
    margin-top: 20px;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
  }

  .mainButton {
    width: 150px;
    margin-top: 20px;
    background: #F3F6F9;
  }

  .dest {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }
}

.singleEmailModal {
  width: 640px !important;
  overflow-y: auto;
}


.reloadIcon {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;

    &.loading {
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    path {
      stroke: #356966;
    }
  }
}


.cartNumberInput {
  input {
    border: 1px solid #356966 !important;
  }
}

.multiCallsModal {
  position: fixed;
  top: 0;
  z-index: 22;
  left: 240px;
  background: #00000080;
  height: 100vh;
  width: 226px;
  padding: 86px 21px 86px 12px;
  overflow-y: auto;

  .addLeadButton {
    margin-top: 10px;
  }
}

.react-datepicker-popper {
  z-index: 999999 !important;
}

.disableScroll {
  overflow: hidden;

  .draggableContainer {
    overflow: hidden;
  }
}
