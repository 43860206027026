@import "variables";

//Toastify start
.Toastify {
  .Toastify__toast {
    background: #EAFBE7;
    color: #32324D;
    border: 1px solid #C6F0C2;
    box-shadow: 0 2px 15px rgba(33, 33, 52, 0.1);

    &.Toastify__toast--error {
      background: #FCECEA;
      border-color: #F5C0B8;
    }
  }
}

//Toastify end

//Pagination start
.pagination__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 13px 15px;
  background: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .pagination__container {
    display: flex;
    padding: 0;
    margin: 0;

    > li {
      margin: 1px;

      &.pagination__page__active {
        a {
          font-weight: 600;
          background: #FFFFFF;
          box-shadow: 0 1px 7px rgba(26, 26, 67, 0.15);
          color: #356966;
          pointer-events: none;
        }
      }

      > a {
        width: 32px;
        height: 32px;
        color: $background;
        text-align: center;
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        border-radius: 4px;
        cursor: pointer;


        &:hover {
          box-shadow: 0 1px 7px rgba(26, 26, 67, 0.15);
        }

        svg {
          margin-left: 1px;
          transform: rotateZ(270deg);

          &.prev__arrow {
            margin-left: -1px;
            transform: rotateZ(90deg);
          }

          path {
            fill: #849291
          }
        }
      }
    }
  }

  .pagination__limit__wrapper {
    display: flex;
    align-items: center;
    position: relative;

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      width: 55px;
      height: 32px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      background: transparent;
      color: $background;
      border: 1px solid #EAEAEF;
      box-shadow: 0 1px 4px rgba(33, 33, 52, 0.1);
      border-radius: 4px;
      cursor: pointer;

      .pagination__limit__arrow {
        margin-bottom: 1px;
        transition: .4s;

        &.limit__show {
          transform: rotateZ(180deg);
        }
      }
    }

    .pagination__limit__items {
      position: absolute;
      width: 55px;
      height: 90px;
      padding: 9px 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: -95px;
      left: 0;
      background: #FFFFFF;
      border: 1px solid #EAEAEF;
      box-shadow: 0 1px 4px rgba(33, 33, 52, 0.1);
      border-radius: 4px;
      z-index: 1;

      div {
        margin: 1px;
        padding: 3px 0;
        cursor: pointer;
        width: 100%;
        transition: all 0.4s;

        &:hover {
          background: rgba(0, 0, 0, 0.06);
          border-radius: 4px;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #849291;
      margin-left: 10px;
    }
  }
}

//Pagination end


//Table start
.table__wrapper {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 260px);
  overflow-x: auto;
  background: #FFFFFF;

  &::-webkit-scrollbar {
    width: 10px;
    background: rgba(29, 39, 64, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(29, 39, 64, 0.2);
  }

  .table {
    background: #FFFFFF;
    border-collapse: collapse;
    width: 100%;

    .th, .td {
      border: 1px solid #EAEAEA;
      word-break: break-word;
      min-width: 165px;
      font-size: 14px;
      padding: 11px 8px;
      color: $background;
      text-align: left;
      vertical-align: middle;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 350px;

      &.checkbox__wrapper {
        min-width: 40px;
        width: 40px;
        padding: 10px;
        vertical-align: top;

        .checkboxWrapper {
          padding: 0;
          margin: 0;
        }
      }

      &:last-child {
        border-right-color: transparent;
      }

      &:first-child {
        border-left-color: transparent;
      }
    }

    .thead {
      .th {
        font-weight: 600;
      }
    }

    .actions__wrapper {
      padding: 0 8px;

      svg {
        margin-top: 1px;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }

      .action__edit__icon {
        margin-right: 15px;

        &.recover {
        }
      }
    }
  }
}

.table__no__result {
  height: 100%;
  min-height: calc(100vh - 265px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: $background;
  background: #FFFFFF;
}

.table__with__pagination__wrapper {
  position: relative;
  background: #FFFFFF;
  min-height: calc(100vh - 205px);
}

//Table end


//Loader start
.loader__wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3);
}

//Loader end

//Modal start
.modal__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &.bottom {
    left: 61px;
    width: calc(100% - 61px);
    transition: .3s;

    .right__modal__wrapper {
      top: unset;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      min-height: 450px;

      .right__modal__content {
        height: 100%;
        padding: 25px 15px;
      }
    }

    .open_close {
      width: 12px;
      height: 12px;
      top: 15px;
      left: unset;
      right: 14px;
      margin: unset;
      padding: 0;
      z-index: 0;

      &:hover {
        opacity: 0.7;
      }

      > svg {
        width: 100%;
        height: 100%;
        margin: 0;

        path {
          fill: #4A4A6A;
        }
      }
    }

    &.leftPosition {
      left: 240px;
      width: calc(100% - 240px);
    }

    .show_modal {
      animation: openBottomMenu 0.3s ease-in-out both;
    }

    .close_modal {
      animation: closeBottomMenu 0.3s ease-in-out both;
    }
  }

  &:not(.bottom) {
    .show_modal {
      animation: openMenu 0.3s ease-in-out both;
    }
  }

  &:not(.bottom) {
    .close_modal {
      animation: closeMenu 0.3s ease-in-out both;
    }
  }
}

.right__modal__wrapper {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: var(--modal-width);
  height: 100%;
  background: #FFFFFF;
  z-index: 1;

  .open_close {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -22px;
    cursor: pointer;
    background: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;
    outline: 0;
    z-index: -1;

    svg {
      transform: rotate(-90deg);
      margin-left: 10px;
    }

    &.close {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  .right__modal__wrapper__header {
    height: 56px;
    font-weight: 500;
    font-size: 20px;
    color: #212529;
    padding: 16px;
    background: #F3F6F9;
  }

  .right__modal__content {
    padding: 16px;
    height: calc(100vh - 56px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0 2px white;
      background: rgb(128, 128, 128);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $background;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #3f3f3f;
    }
  }

  @keyframes closeMenu {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(var(--modal-width) + 25px));
    }
  }

  @keyframes openMenu {
    from {
      transform: translateX(calc(var(--modal-width) + 25px));
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes closeBottomMenu {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(var(--modal-width) + 25px));
    }
  }

  @keyframes openBottomMenu {
    from {
      transform: translateY(calc(var(--modal-width) + 25px));
    }
    to {
      transform: translateY(0);
    }
  }
}

.modal__wrapper {
  width: calc(100vw - 60px);
  max-width: 1060px;
  height: auto;
  min-height: 200px;
  max-height: calc(100vh - 60px);
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 4px 21px rgba(0, 0, 0, 0.06);
  transform: scale(0.3);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  z-index: 1;
  padding: 23px 15px;
  will-change: tranform;

  &.delete__modal__wrapper {
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 23px 0 20px;

    .delete__modal__text__wrapper {
      padding: 20px 15px;
      text-align: center;

      p {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #32324D;
      }
    }


    .delete__modal__buttons__wrapper {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #EAEAEF;
      padding: 15px 15px 0;

      button {
        width: calc(50% - 5px);

        &:first-child {
          border-color: #DCDCE4;
        }

        &:last-child {
          border-color: #F5C0B8;
          background: #FCECEA;
          color: #D02B20;
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
            margin-bottom: 2px;

            path {
              fill: #D02B20;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: calc(100vw - 20px);
  }

  &.show_modal {
    transform: scale(1);
    opacity: 1;
  }

  .close {
    position: absolute;
    height: 16px;
    width: 16px;
    right: 8px;
    top: 8px;
    cursor: pointer;

    &:hover {
      opacity: .6;
    }
  }
}

//Modal end

//Contacts page start
.contacts__content {
  position: relative;
  min-height: calc(100% - 86px);
  padding: 25px 15px;
  background: #F3F6F9;

  .contacts__buttons__wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;

    &.permission {
      .mainButton {
        &:first-child {
          background: transparent;
        }
      }
    }

    .mainButton {
      width: 105px;

      svg {
        margin-right: 10px;

        path {
          fill: $background;
          fill-opacity: 1;
        }
      }
    }
  }
}

.new__contact__input__wrapper {
  margin-bottom: 20px;
  margin-right: 15px;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .range__fields {
    display: flex;
    align-items: center;

    > span {
      margin: 16px 10px 0;
    }
  }

  .inputWrapper {
    label {
      height: 16px;
    }

    input {
      padding: 7px 15px;
      height: 40px;
    }
  }

  .fus__control {
    height: 40px;
    background: #F3F5F7 !important;

    &.fus__control--is-focused,
    &.fus__control--is-focused:hover {
      height: 40px;
      border-width: 1px;
    }
  }

  .fus__value-container {
    padding: 2px 13px;
  }

  .fus__single-value,
  .fus__placeholder {
    margin: 0 2px;
    padding: 0;
  }
}

.new__users__buttons__wrapper {
  display: flex;
  justify-content: space-between;

  > button {
    width: 130px;
    background: #356966;
    color: #FFFFFF;
  }
}

.new__contact__buttons__wrapper {
  display: flex;

  button {
    &:first-child {
      width: 165px;
    }

    &:last-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      width: 80px;
    }
  }
}

//Contacts page end

//Permission page start
.permission__name__wrapper {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEAEF;
  box-shadow: 0 1px 4px rgba(26, 26, 67, 0.1);
  border-radius: 4px;
  padding: 24px 35px;

  .permission__title__wrapper {
    display: flex;
    justify-content: space-between;

    .permission__title {
      .permission__role {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $background;
      }

      .permission__info {
        font-size: 12px;
        line-height: 16px;
        color: #849291;
        margin-top: 5px;
      }
    }

    .permission__user__count {
      background: #EAEAEF;
      border: 1px solid #DCDCE4;
      border-radius: 4px;
      padding: 8px 15px;
      height: 32px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #666687;
    }
  }

  .permission__fields__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    > div {
      width: calc(50% - 9px);

      input {
        padding: 7px 16px;
      }

      input, textarea {
        background: transparent;
      }

      textarea {
        resize: none;
        height: 80px;
        min-height: auto;
      }
    }
  }
}

.permissions__table__wrapper {
  position: relative;
  background: #FFFFFF;
  width: 100%;
  padding: 10px;
  margin-top: 15px;

  .error {
    position: absolute;
    top: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #D02B20;
  }

  .permissions__table__header {
    display: flex;
    padding: 15px 20px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #849291;
  }

  .permissions__table__header,
  .permissions__table {
    .role__name {
      width: 200px;
    }

    .edit {
      text-align: center;
      width: 150px;

      label {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;

        .checkmark {
          position: relative;
        }
      }
    }
  }

  .permissions__table {
    .permissions__table__tr {
      .permissions__table__tr__parent {
        display: flex;
        position: relative;
        background: #F3F6F9;
        padding: 15px 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $background;

        .permissions__table__tr__arrow {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 10px;
          right: 20px;
          height: 32px;
          width: 32px;
          border-radius: 50%;
          background: #DCDCE4;
          cursor: pointer;
          border: 0;
          outline: 0;

          svg {
            transform: rotateZ(0deg);
            transition: .4s;

            &.up {
              transform: rotateZ(-180deg);
            }
          }
        }
      }

      .permissions__table__tr__children {
        display: flex;
        background: transparent;
        padding: 15px 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $background;

        .role__name {
          padding-left: 25px;
        }
      }
    }
  }
}

//Permission page end


//Invoices page start
.invoices__buttons__wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;

  .bulk__select__wrapper {
    margin-right: 10px;
  }

  .mainButton {
    width: auto;
    border-color: #DBDBDB;
    padding: 0 15px;

    .filters__count {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      background: #FFD831;
      color: #000;
      font-size: 13px;
      font-weight: 600;
    }

    svg {
      margin-right: 10px;
    }
  }
}

.invoice__wrapper {
  width: 100%;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 86px);
  background: #F3F6F9;

  .errorText {
    position: absolute;
    left: 0;
    bottom: -17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #D02B20;
  }
}

.invoice__content {
  margin: 0 15px;
  padding: 15px;
  background: #FFFFFF;

  .invoice__header {
    background: #F3F6F9;
    padding: 35px 25px;

    .invoice__header__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      h2 {
        display: flex;
        font-weight: 700;
        font-size: 20px;
        color: #000000;

        .invoice__status {
          font-size: 13px;
          font-weight: 400;
          padding: 3px 10px;
          width: max-content;
          margin-left: 15px;
          border-radius: 4px;
        }
      }

      .invoice__header_top__btns__wrapper {
        display: flex;
        align-items: center;
        margin-top: -15px;

        .checkboxWrapper {
          width: max-content;
          font-size: 13px;
          margin-bottom: 0;
          padding-left: 22px;
          cursor: default;

          .checkmark {
            width: 16px;
            height: 16px;
            margin-top: 1px;

            &:after {
              left: 4.8px;
              top: 1px;
              width: 3px;
              height: 7px;
            }
          }
        }

        button {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          color: #356966;
          outline: 0;
          border: 0;
          background: transparent;
          cursor: pointer;
          min-width: 85px;
          margin-left: 30px;

          &:first-child {
            width: 130px;
          }

          .loader {
            position: relative;
            top: -1px;
            margin: 0 7px 0 -1px;
          }

          &:hover {
            opacity: .7;
          }

          svg {
            margin-right: 10px;
          }

          &:first-child {
            margin-right: 35px;
          }
        }
      }
    }

    .invoice__header__fields__wrapper {
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        justify-content: space-between;
        width: 525px;

        &:first-child {
          width: 380px;

          input {
            padding: 10px 16px;
            background: #FFFFFF;
          }
        }

        .date__picker__wrapper {
          width: 255px;
          position: relative;

          input {
            background: #FFFFFF;
          }
        }
      }
    }
  }

  .invoice__addresses__wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .invoice__addresses__billing__address {
      width: calc((100% - 105px) / 3);

      .invoice__addresses__title {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #000000;

        button {
          font-weight: 600;
          font-size: 13px;
          color: #356966;
          border: 0;
          outline: 0;
          background: transparent;
          cursor: pointer;

          &:hover {
            opacity: .7;
          }
        }
      }

      .invoice__addresses__input__wrapper {
        margin-top: 20px;

        input {
          padding: 10px 16px;
          background: transparent;
        }
      }
    }
  }

  .invoice__table__wrapper {
    position: relative;
    margin-top: 30px;
    border: 1px solid transparent;

    &.error {
      border-color: $error;
    }

    .invoice__table__header,
    .invoice__table__body .tr {
      display: flex;
      justify-content: space-between;

      .td {
        margin-right: 40px;
        width: 190px;
        min-width: 100px;
        max-width: 190px;
        flex: 1 1;

        &:first-child {
          width: 250px;
          min-width: 200px;
          max-width: 380px;
        }

        &:last-child {
          margin-right: 0;
        }

        input {
          padding: 10px 16px;
          background: #FFFFFF;
        }

        &.delete__table__item {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 20px;
          min-width: 20px;
          max-width: 20px;

          .delete__btn__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #849291;
            border: 0;
            outline: 0;
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            svg {
              width: 5px;
              height: 5px;

              path {
                fill: #FFFFFF;
              }
            }
          }
        }
      }
    }

    .invoice__table__body {
      padding: 20px 35px 25px;
      background: #F3F6F9;

      .tr {
        margin-bottom: 20px;

        .td__line__total {
          height: 100%;
          width: 100%;
          font-weight: 600;
          font-size: 14px;
          display: flex;
          align-items: center;

          color: #000000;
        }
      }
    }

    .invoice__table__header {
      padding: 17.4px 35px;
      background: #849291;
      color: #FFFFFF;
    }

    .invoice__table__add__line {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 17px;
      color: #356966;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-top: -4px;
        margin-right: 6px;

        path {
          fill: #356966;
        }
      }
    }

    .invoice__table__view__payments {
      color: #356966;
      font-size: 17px;
      border: 0;
      outline: 0;
      border-bottom: 1px solid #356966;
      cursor: pointer;
      background: transparent;
      margin-top: 15px;

      &:hover {
        opacity: .7;
      }
    }
  }

  .invoice__note__wrapper {
    margin-top: 40px;
    display: flex;

    > div {
      width: 380px;

      &.invoice__total__wrapper {
        position: relative;
        width: calc(100% - 420px);
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        margin-top: 15px;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 210px;
          position: relative;
          margin-bottom: 15px;

          > div {
            width: 180px;
            font-size: 14px;
            color: #000000;

            .fusIndicatorsContainer {
              font-size: 13px;
              padding: 0 10px;
              font-weight: 400;
            }

            &:last-child {
              font-weight: 600;
              text-align: right;
              min-width: 50px;
            }
          }

          &.total {
            > div {
              font-weight: 700;
              font-size: 20px;
              color: #292929;

              &.error {
                text-align: right;
                color: $error;
              }
            }
          }
        }

        .errorText {
          right: 0;
          text-align: right;
          bottom: -5px;
        }
      }
    }
  }
}

.invoice__buttons__wrapper {
  display: flex;
  padding: 15px;
  margin: 0 15px;
  box-shadow: 0px -3px 33px rgba(0, 0, 0, 0.1);
  background: #FFFFFF;

  button {
    width: max-content;
    padding: 15px;

    &:nth-child(1) {
      width: 65px;
    }

    &:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      margin-right: 20px;
    }

    &:nth-child(3) {
      color: #FFFFFF;
      background: #356966;
    }
  }
}

.tax__field__wrapper {
  display: flex;
  background: #F3F6F9;
  border: 1px solid #DCDCE4;
  border-radius: 4px;

  &:has(input:focus) {
    border-color: #356966;
  }

  > input {
    width: calc(100% - 44px);
    border: 0;
    outline: 0;
    background: transparent;
    padding: 8px 5px 8px 15px;
  }

  .selectWrapper .fus__control {
    width: 44px;
    min-width: auto;
    border: 0;
    background: #F3F6F9;

    .fus__value-container {
      padding: 0;

      .fus__single-value {
        padding: 0;
        margin: 2px 5px 0 0;
      }
    }


    .fusIndicatorsContainer {
      padding: 0 15px 0 0 !important;
    }
  }
}

.invoice__payment {
  .inputWrapper {
    .currency {
      left: 15.8px;

      ~ input {
        padding-left: 25px !important;
      }
    }
  }
}

.payment__status__wrapper {
  display: flex;
  justify-content: space-between;

  button {
    display: block;
    margin-left: auto;
    border: 0;
    color: #356966;
    border-bottom: 1px solid #356966;
    background: transparent;
    cursor: pointer;
  }
}

//Invoices page end


//BulkSelect start
.bulk__select__wrapper {
  position: relative;
  z-index: 2;

  &.open {
    button {
      svg {
        transform: rotateZ(-180deg);
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    color: #356966;
    width: 115px;
    height: 40px;
    background: #F3F6F9;
    border: 1px solid #356966;
    border-radius: 5px;
    padding: 5px 10px 4px;
    cursor: pointer;

    svg {
      transition: 0.4s;
      margin-left: 5px;

      path {
        fill: #356966;
        fill-opacity: 1;
      }
    }
  }

  .bulk__select__options__wrapper {
    position: absolute;
    right: 0;
    top: 46px;
    width: max-content;
    background: #FFFFFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 10px 20px;

    div {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #292929;
      margin: 10px 0;
      cursor: pointer;

      &:hover {
        color: #356966;
      }
    }
  }
}

//BulkSelect end
//DatePicker start
.date__picker__wrapper {
  min-width: 233px;

  > label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #000000;
    margin-bottom: 4px;
    width: max-content;
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      &:has(input:focus) {
        .react-datepicker__calendar-icon {
          path {
            fill: #356966 !important;
          }
        }
      }

      .react-datepicker__calendar-icon {
        width: 16px;
        height: 16px;
        padding: 6px 5px 5px;
        margin: 6px 0 0 11px;

        path {
          transform: scale(28);
          fill: #849291;
          d: path("M2.57905 1.99382V0H4.51211V1.99382H11.4876V0H13.4207V1.99382H13.8406C15.6863 1.99382 15.9762 2.32511 16 3.94424V14.0004C16 15.416 15.8725 16 14.1321 16H1.87877C0.138311 16 0 15.5756 0 13.8569V4.14021C0.0233666 2.77398 0.155542 1.99382 2.0008 1.99382L2.57905 1.99382ZM1.54696 13.5788C1.54696 14.1196 1.71005 14.1555 2.06409 14.1555H14.0009C14.3545 14.1555 14.4536 14.1471 14.4536 13.6054V5.48897C14.4437 5.07089 14.3082 4.9974 14.0332 4.9974H1.93782C1.66261 4.9974 1.54318 5.05782 1.54696 5.37955L1.54696 13.5788Z")
        }
      }

      > input {
        height: 40px;
        width: 100%;
        padding-left: 40px;
        background: #F3F5F7;
        border-radius: 5px;
        border: 1px solid #DDE2E7;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #000000;

        &:focus {
          border-color: #356966;
        }
      }

      &:hover {
        > .react-datepicker__close-icon {
          display: block;
        }
      }

      > .react-datepicker__close-icon {
        display: none;

        &:after {
          background: transparent;
          font-size: 20px;
          color: #849291;
        }
      }
    }
  }

  &.error {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        &:has(input:focus) {
          .react-datepicker__calendar-icon {
            path {
              fill: $error !important;
            }
          }
        }

        .react-datepicker__calendar-icon {
          path {
            fill: $error !important;
          }
        }

        > input {
          border-color: $error;

          &:focus {
            border-color: $error;
          }
        }
      }
    }

    .errorText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $error;
      margin-top: 4px;
    }
  }
}

.react-datepicker__tab-loop {
  .react-datepicker-popper {
    padding-top: 4px;
    z-index: 1001;

    .react-datepicker {
      background: #FFFFFF;
      border: 1px solid #EAEAEF;
      box-shadow: 0 1px 4px rgba(33, 33, 52, 0.1);
      border-radius: 4px;

      .react-datepicker__navigation {
        display: none;
      }

      .react-datepicker__month-container {
        padding: 5px;

        .react-datepicker__header {
          background: #FFFFFF;
          border: 0;
          padding: 8px 0 5px;

          .react-datepicker__current-month {
            display: none;
          }

          .react-datepicker__header__dropdown {
            display: flex;
            margin-bottom: 15px;

            .react-datepicker__month-dropdown-container {
              margin: 0;
              padding: 0;
              width: 104px;

              .react-datepicker__month-dropdown {
                background: #FFFFFF;
                border: 1px solid #EAEAEF;
                box-shadow: 0 1px 4px rgba(33, 33, 52, 0.1);
                border-radius: 4px;
                left: 0;
                top: 4px;

                .react-datepicker__month-option {
                  &:hover {
                    background: #F3F6F9;
                  }

                  &.react-datepicker__month-option--selected_month {
                    background: #FFD831;
                    color: black;
                  }
                }
              }

              .react-datepicker__month-read-view {
                //visibility: visible !important;
                text-align: left;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #32324D;

                .react-datepicker__month-read-view--selected-month {
                  display: block;
                  margin-top: -2px;
                  padding: 0 15px 0 14px;

                }

                .react-datepicker__month-read-view--down-arrow {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 5px 5px 0 5px;
                  border-color: #999999 transparent transparent transparent;
                  transform: unset;
                  right: 1px;
                  top: 4px;
                }
              }
            }

            .react-datepicker__year-dropdown-container {
              margin: 0;

              .react-datepicker__year-dropdown {
                background: #FFFFFF;
                border: 1px solid #EAEAEF;
                box-shadow: 0 1px 4px rgba(33, 33, 52, 0.1);
                border-radius: 4px;
                top: 4px;

                .react-datepicker__year-option {
                  &:hover {
                    background: #F3F6F9;
                  }

                  &.react-datepicker__year-option--selected_year {
                    background: #FFD831;
                    color: black;
                  }
                }
              }

              .react-datepicker__year-read-view {
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #32324D;

                .react-datepicker__year-read-view--selected-year {
                  display: block;
                  margin-top: -2px;
                  padding: 0 18px 0 15px;
                }

                .react-datepicker__year-read-view--down-arrow {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 5px 5px 0 5px;
                  border-color: #999999 transparent transparent transparent;
                  transform: unset;
                  right: 1px;
                  top: 4px;
                }
              }
            }
          }

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              font-weight: 600;
              width: 2.5em;
              line-height: 1.5em;
              color: #292929;
            }
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              width: 2.5em;
              line-height: 2.5em;
              border-radius: 4px;
              border: 1px solid transparent;

              &.react-datepicker__day--outside-month {
                color: #66668785;
              }

              &:hover {
                border-color: #C0C0CF;
                background: #FFFFFF;
                color: black;
              }

              &.react-datepicker__day--keyboard-selected {
                background: transparent;
              }

              &.react-datepicker__day--today {
                background: #F3F6F9;
                color: #356966;
              }

              &.react-datepicker__day--in-selecting-range,
              &.react-datepicker__day--in-range {
                background: rgba(255, 216, 49, 0.67);
                color: black;
              }

              &.react-datepicker__day--selecting-range-end,
              &.react-datepicker__day--range-end,
              &.react-datepicker__day--selected {
                background: #FFD831;
                color: black;
              }
            }
          }
        }
      }

      .react-datepicker__triangle {
        &::after,
        &::before {
          content: none;
        }
      }
    }
  }
}


//DatePicker end


//PrintModal start
.invoices__right__modal {
  .right__modal__content {
    min-height: calc(100vh);
    padding-right: 0;
  }
}

.print__modal__content {
  padding: 10px 15px;

  .print__modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .print__modal__header__title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      color: #356966;

      h2 {
        font-weight: 700;
        font-size: 22px;
        color: #292929;
        margin-right: 20px;
      }
    }

    .print__modal__header__btns__wrapper {
      display: flex;

      button {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        color: #356966;
        border: 0;
        background: transparent;
        cursor: pointer;

        &:first-child {
          width: 130px;
        }

        .loader {
          position: relative;
          top: -1px;
          margin: 0 7px 0 -1px;
        }

        &:hover {
          opacity: .7;
        }

        svg {
          margin-right: 10px;
        }

        &:first-child {
          margin-right: 35px;
        }
      }
    }
  }

  .print__modal__dates {
    margin: 20px 0 30px;

    .print__modal__invoice__date {
      display: flex;
      font-size: 13px;
      color: #292929;
      margin: 10px 0;

      span {
        display: block;
        width: 95px;
        color: #7E868C;
      }
    }
  }

  .print__modal__info {
    h3 {
      font-weight: 500;
      font-size: 18px;
      color: #356966;
      margin-bottom: 10px;
    }

    .print__modal__info__address__wrapper {
      div {
        font-size: 13px;
        color: #292929;
        margin-bottom: 4px;
      }
    }

    .print__modal__details__wrapper {
      display: flex;
      margin-top: 30px;

      .print__modal__details {
        width: calc(50% - 5px);

        div {
          font-size: 13px;
          color: #292929;
          margin-bottom: 4px;

          &.print__modal__details__title {
            font-weight: 600;
          }
        }
      }
    }

    .print__modal__table__wrapper {
      width: 100%;
      margin: 30px 0;

      .table {
        border-collapse: collapse;
        width: 100%;

        .thead {
          .tr {
            background: #F8F9FA;
            font-weight: 600;
            font-size: 12px;
            color: #292929;
          }
        }

        .tbody {
          .tr {
            border-bottom: 1px solid #F1F3F5;

            .td {
              padding: 12.8px 3px;
            }
          }
        }

        .tr {
          text-align: left;
          font-size: 13px;

          .th, .td {
            padding: 8px 3px;
            min-width: 20px;
          }
        }
      }
    }

    .print__modal__total__wrapper {
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      > div {
        display: flex;
        justify-content: space-between;
        width: 200px;
        margin-bottom: 15px;

        > div {
          width: 80px;
          font-size: 13px;
          color: #7E868C;

          &:last-child {
            width: 120px;
            color: #292929;
            text-align: right;
            word-break: break-word;
          }
        }

        &.total {
          > div {
            font-weight: 700;
            font-size: 16px;
            color: #292929;
          }
        }
      }
    }
  }
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}

.print {
  #root > div:not(#modal) {
    display: none !important;
  }

  #modal {
    position: static;

    .print__modal__content,
    .right__modal__wrapper {
      position: static;
      background: rgba(0, 0, 0, 0.5);
      left: 0;
      right: 0;
      width: 100%;

      .right__modal__content {
        overflow: hidden;
        height: 100%;
        visibility: hidden;
      }

      @media print {
        background: #FFFFFF;

        .right__modal__content {
          visibility: visible;
          width: 100%;

          .print__modal__header {
            .print__modal__header__btns__wrapper {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

//PrintModal end


//InvoicePaymentModal start
.invoices__payment__modal {
  .right__modal__content {
    padding-bottom: 5px !important;
  }

  .invoice__payment__modal__wrapper {
    height: 100%;
    min-height: auto;
    width: 100%;
    padding-bottom: 20px;

    h2 {
      font-weight: 500;
      font-size: 20px;
      color: #212529;
      margin-bottom: 15px;
    }
  }
}

.invoice__payment__modal__table__wrapper {
  overflow: hidden;
  overflow-y: auto;
  width: max-content;
  padding-right: 8px;
  height: calc(100% - 40px);

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 2px white;
    background: rgb(128, 128, 128);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $background;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #3f3f3f;
  }

  .table {
    .td {
      padding: 4px 8px;

      label {
        display: none;
      }

      .react-datepicker__calendar-icon {
        height: 28px !important;
        margin-left: 8px !important;
      }

      .selectWrapper .fus__control {
        min-height: 28px;
        height: 28px;
        background: transparent;
        border-color: transparent;
        border-radius: 0;

        &.fus__control--is-focused {
          border-color: #356966 !important;
        }


        .fus__placeholder {
          color: #6C757D;
        }

        .fus__single-value,
        .fus__placeholder {
          padding: 0 !important;
        }

        > div {
          padding: 0 10px;

          span {
            display: block;
            margin-bottom: 3px;
          }
        }
      }

      .inputWrapper {
        &.error {
          input {
            border-color: $error;
          }
        }

        .currency {
          top: 4px;
        }

        .errorText {
          display: none;
        }

        input {
          &::placeholder {
            font-size: 14px;
          }

          padding: 0 12px;
        }
      }

      input {
        height: 28px;
        background: transparent;
        border-color: transparent;
        border-radius: 0;

        &:focus {
          border-color: #356966;
        }
      }

      &.actions__wrapper {
        > div {
          display: flex;
          align-items: center;
        }

        .save {
          width: 53px;
          height: 22px;
          background: #356966;
          border: none;
          outline: none;
          margin-right: 12px;
          padding: 0 12px;
          border-radius: 4px;
          font-weight: 600;
          font-size: 12px;
          color: #FFFFFF;
          cursor: pointer;
          position: relative;

          &:disabled {
            background: #6C757D;
            cursor: default;
          }

          &:not(:disabled):hover {
            opacity: 0.7;
          }

          .loader {
            margin: 0 0 0 6px;
          }
        }

        svg {
          margin-top: -1px;
        }
      }
    }
  }
}

.invoices__right__modal {
  .invoice__payment__history__wrapper {
    height: calc(100% - 60px);
    padding-right: 16px;
  }
}

.invoice__payment__history__wrapper {
  height: 100%;

  .invoice__payment__modal__table__wrapper {
    width: 100%;
    height: 100%;
    padding: 0;

    .table {
      .th, .td {
        padding: 11px 8px;

        &.actions__wrapper {
          min-width: 70px;
          width: 70px;
        }
      }
    }
  }
}

.fus__menu-portal {
  z-index: 1001 !important;
}

.email__modal__wrapper {
  width: 342px;
  min-height: auto;
  padding: 10px 20px;

  > div {
    display: flex;
    padding: 10px 0;

    &:nth-child(3) {
      border-top: 1px solid #E4E4E4;
    }

    > div {
      font-size: 16px;

      > div {
        font-weight: 600;
        margin-bottom: 10px;

        &.error {
          font-weight: 400;
          border-radius: 5px;
          background: rgba(224, 0, 0, 0.10);
          color: $error;
          font-size: 13px;
          padding: 2px 11px;
          margin-top: 10px;
          margin-bottom: 0;
          width: max-content;
        }
      }
    }

    svg {
      min-width: 26px;
      margin-right: 15px;
    }
  }
}

//InvoicePaymentModal end

//EmailTemplate start
.templates__table__wrapper {
  .table {
    .tr {
      .th, .td {
        .template__html {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;

          * {
            display: inline;
          }


          img {
            width: 17px;
            height: 17px;
            margin: 0 10px 0 0 !important;
          }

          * {
            margin-right: 5px;
            font-size: 14px !important;
            line-height: 16px !important;
          }

          br,
          hr {
            display: none;
          }
        }

        &:first-child {
          min-width: 100px;
          max-width: 150px;
          width: 12%;
        }
      }
    }
  }
}

.email__template__content {
  background: #F3F6F9;
  min-height: calc(100vh - 86px);
  position: relative;

  .email__template__wrapper {
    display: flex;
    height: calc(100vh - 86px);

    .email__template__left__block {
      width: calc(100% - 360px);
      margin: 20px 15px;

      .inputWrapper {
        position: relative;

        .errorText {
          position: absolute;
          bottom: -16px;
        }
      }

      .email__template__left__top {
        display: flex;
        align-items: center;

        .email__template__new {
          height: 21px;
          border-radius: 99px;
          font-size: 12px;
          font-weight: 700;
          color: #FFFFFF;
          line-height: 12px;
          padding: 4px 8px;
          background: #356966;
          margin-right: 6px;
        }

        .inputWrapper {
          input {
            height: 36px;
            font-size: 20px;
            background: #FFFFFF;
            padding: 7px 8px;
            font-weight: 500;
          }
        }

        button {
          height: 36px;
          min-width: 80px;
          width: 80px;

          &.noBorder {
            width: 56px;
            min-width: auto;
            color: #292929;
            background: transparent;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            padding: 0;
            margin: 0 10px 0 20px;
          }
        }
      }

      .email__template__left__bottom {
        margin-top: 20px;
        padding: 20px 15px;
        background: #FFFFFF;
        min-height: calc(100vh - 163px);

        .inputWrapper {
          margin-bottom: 25px;

          input {
            padding: 15px 10px;
          }
        }
      }
    }

    .email__template__right__block {
      width: 360px;
      background: #FFFFFF;
      height: 100%;
      padding: 20px;

      .selectWrapper {
        margin-bottom: 30px;

        .fus__placeholder {
          color: #6C757D;
          padding: 0;
        }

        .fus__single-value {
          padding: 0;
        }
      }
    }
  }
}

//EmailTemplate end

//Jodit Editor start
.jodit__wrapper {
  position: relative;

  &.error {
    .jodit-container {
      border-color: $error;
    }
  }

  .errorText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $error;
    position: absolute;
  }

  .jodit-container {
    border-radius: 4px;
    border-color: #DDE2E7;

    &:has(.jodit-wysiwyg:focus),
    &:has(.ace_focus) {
      border-color: #356966;
    }

    .jodit-toolbar__box {
      background: #FFFFFF;
      border-radius: 4px 4px 0 0;

      .jodit-toolbar-button_paste {
        .jodit-toolbar-button__trigger {
          display: none;
        }
      }
    }

    .jodit-workplace {
      background: #F3F5F7;
      border-radius: 0 0 4px 4px;
      font-size: 14px;

      .jodit-source .ace_dark,
      .jodit-wysiwyg {
        min-height: 120px;
        max-height: calc(100vh - 370px);

        &::-webkit-scrollbar {
          width: 4px;
          height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 0 1.5px #F3F5F7;
          background: rgb(128, 128, 128);
          margin: 4px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $background;
          border-radius: 4px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #3f3f3f;
        }
      }
    }

    .jodit-status-bar {
      display: none;
    }
  }
}

.jodit-popup {
  background: #FFFFFF;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;

  .jodit-popup__content {
    &::-webkit-scrollbar {
      width: 4px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0 1.5px white;
      background: rgb(128, 128, 128);
      margin: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $background;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #3f3f3f;
    }
  }
}

.jodit-dialog {
  &:not(.jodit-dialog_fullsize_true) {
    .jodit-dialog__panel {
      width: calc(100% - 40px) !important;
      max-width: 640px;
    }
  }

  .jodit-dialog__panel {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: #F3F4F6;

    .jodit-dialog__header {
      background: #FFFFFF;
      border-radius: 5px 5px 0 0;
    }

    .jodit-dialog__content {
      padding: 10px 48px;
      margin-bottom: 10px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0 1.5px white;
        background: rgb(128, 128, 128);
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $background;
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #3f3f3f;
      }

      .jodit__preview-box.jodit-context {
        width: 100%;
        height: 100%;
        min-width: auto !important;
        min-height: auto !important;
        word-break: break-word;
        padding: 0 !important;
        box-sizing: border-box;
        max-width: 640px;

        * {
          word-break: break-word;
        }
      }
    }

    .jodit-dialog__resizer {
      display: none;
    }

    .jodit-toolbar-button__button {
      &:hover {
        opacity: .6;
        background: transparent;
      }
    }
  }
}

.jodit-ui-button {
  border-radius: 4px;

  &:focus:not([disabled]) {
    outline: 1px dashed #356966;
  }

  &.jodit-ui-button_keep,
  &.jodit-ui-button_ok,
  &.jodit-ui-button_variant_primary {
    background: #356966 !important;

    &:hover {
      background: #356966 !important;
    }
  }
}

[class*="jodit"] {
  ul {
    list-style-type: disc;
  }

  ul, ol {
    padding-inline-start: 40px;
  }

  .jodit-ui-input__wrapper {
    border-radius: 4px;

    & input {
      border-radius: 4px;

    }

    &:has(input:focus) {
      border-color: #356966;
    }
  }
}

//Jodit Editor end


//Notification start

.notifications {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  background: #F3F6F9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-right: 13px;

  .notifications_icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .dot {
    position: absolute;
    background: #FFD831;
    height: 8px;
    width: 8px;
    top: 7px;
    right: 7px;
    border-radius: 50%;
  }

  .notification_wrapper {
    position: absolute;
    right: 0;
    top: 60px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.20);
    width: 440px;
    height: 610px;
    cursor: default;


    .notification_footer {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 0 0 4px 4px;
      transition: all 0.4s;
      color: #32324D;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;

      &:hover {
        background: #F3F6F9;
      }

    }
  }

}

.notification_header {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: #292929;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .mark_all_read_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      color: #292929;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 3px;
    }
  }
}

.notification_no_data {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.notification_body {
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.4s;

  .notification_avatar {
    margin-right: 16px;
    position: relative;

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .dot {
      position: absolute;
      background: #FFD831;
      height: 8px;
      width: 8px;
      right: 7px;
      border-radius: 50%;
      left: -8px;
      top: 0;
    }
  }

  .notification_info {

    .date {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #A5ACB8;
    }

    .delete_btn {
      display: flex;
      height: 28px;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #DDDEE1;
      background: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #3C4257;
      cursor: pointer;
      margin-top: 10px;
      transition: all 0.4s;

      &.disabled {
        cursor: default;
        pointer-events: none;
        color: #FFFFFF;
        outline-color: #69696a;
        background: #69696a;
        height: 28px;
        width: 60px;
      }

      &.disabled_another {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }

      &:hover {
        color: #FFFFFF;
        background: red;
        border-color: red;
      }
    }
  }

  &:hover {
    background: #F3F6F9;
    box-shadow: 0 1px 0 0 #E4E8EE;
  }
}

.notification_scroll_block {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 2px white;
    background: rgb(128, 128, 128);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $background;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #3f3f3f;
  }
}

//Notification end

//Dashboard start

.dashboard_wrapper_loader {
  width: 100%;
  height: calc(100vh - 86px);
  position: relative;
}

.dashboard_wrapper {
  width: 100%;
  padding: 30px 20px 30px 15px;

  .dashboard_header_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    p {
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .dashboard_header_title_menu_wrapper {
      position: relative;

      .dashboard_header_title_icon {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #DBDBDB;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .dashboard_header_menu_wrapper {
        position: absolute;
        border-radius: 10px;
        border: 1px solid #DBDBDB;
        background: #FFF;
        box-shadow: 0 4px 90px 0 rgba(163, 171, 185, 0.24);
        padding: 22px 18px 13px;
        top: 40px;
        right: 0;
        z-index: 9999;


        .dashboard_header_menu_item {
          border-radius: 3px;
          border: 1px solid #DBDBDB;
          background: #FFF;
          padding: 7px 14px;
          width: 300px;
          margin-bottom: 3px;
        }

        .dashboard_header_menu_button_wrapper {
          margin-top: 7px;
          display: flex;

          .dashboard_header_menu_button {
            max-width: 65px;
            margin-right: 10px;
          }
        }
      }
    }


  }

  .dashboard_header_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    margin-bottom: 45px;

    .dashboard_header_left {
      width: 80%;
      height: 100%;
      flex: 1;

      .dashboard_lead_statistics_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 45px;

        @media only screen and (max-width: 1230px) {
          flex-direction: column;
        }
      }

      .job_statistics_title {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
      }
    }

    .dashboard_header_right {
      padding: 18px 12px 12px 15px;
      width: 20%;
      background: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0 2px 6px 0 rgba(13, 10, 44, 0.08);
      margin-left: 15px;
      gap: 12px 0;
      display: flex;
      flex-direction: column;


      .dashboard_header_right_title {
        color: #292929;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }

      .dashboard_header_right_user_wrapper {
        padding: 12px 11px;
        border-radius: 16px;
        border: 1px solid #DFDFDF;
        background: #FFF;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.4s;

        &:hover, &.active {
          background: #292929;

          .dashboard_header_right_user_info {
            p {
              color: #FFFFFF;

              &:last-child {
                color: #F3F6F9;
              }
            }
          }
        }

        .dashboard_header_right_user_avatar {
          width: 36px;
          height: 36px;
          border-radius: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .dashboard_header_right_user_info {
          margin-left: 10px;

          p {
            color: #292929;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.32px;

            &:last-child {
              color: #849291;
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.24px;
            }
          }
        }
      }
    }
  }

  .dashboard_body {

    .dashboard_body_title {
      //padding-top: 45px;
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .chart_block {
    margin-bottom: 45px;
  }

}

.leadsStatistic_wrapper {
  width: calc(100% / 2 - 8px);
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0 2px 6px 0 rgba(13, 10, 44, 0.08);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  @media only screen and (max-width: 1230px) {
    width: 100%;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }


  .leadsStatistic_title {
    color: #9291A5;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  h2 {
    color: #1E1B39;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 5px;
  }

  .leadsStatistic_total {
    margin-top: 20px;
    color: #1E1B39;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
  }

  .leadsStatistic_percent {
    margin-top: 15px;
    display: flex;
    align-items: center;

    &.down {
      p {
        color: #E40000;
      }

      svg {
        rotate: 90deg;

        path {
          fill: #E40000;
        }
      }
    }

    p {
      color: #04CE00;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-right: 3px;
    }
  }
}

.leadsStatistic_modal_wrapper {
  padding: 0 60px 0 10px;

  .leadsStatistic_modal_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h2 {
      color: #1E1B39;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }


    .calendar {
      display: flex;
      align-items: center;

      .selectWrapper {
        padding-top: 3px;
        margin-left: 10px;

        .fus__control {
          min-height: 40px;
        }
      }
    }
  }
}


.job-statistic_wrapper {
  width: 100%;
  height: 100%;
  padding: 40px 30px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0 2px 6px 0 rgba(13, 10, 44, 0.08);
  display: flex;

  .job-statistic_left {
    width: 75%;
    height: 100%;

    .job-statistic_left_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      @media only screen and (max-width: 1400px) {
        flex-direction: column;
        align-items: flex-start;

        h2 {
          margin-bottom: 10px;
        }
      }

      h2 {
        color: #1E1B39;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

      .calendar {
        display: flex;
        align-items: center;

        .selectWrapper {
          padding-top: 3px;
          margin-left: 10px;

          .fus__control {
            min-height: 40px;
          }
        }
      }

    }
  }

  .job-statistic_right {
    width: 25%;
    padding-left: 12px;
    display: flex;
    flex-direction: column;

    .job-statistic_right_total {
      padding: 15px 25px;
      border-radius: 100px;
      border: 1px solid #356966;
      background: #F2F1FF;
      color: #356966;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      //margin-bottom: 145px;
    }

    .job-statistic_right_category_wrapper {
      margin: auto 0;
    }

    .job-statistic_right_category {
      display: flex;
      margin-top: auto;
      padding-left: 10px;
      margin-bottom: 30px;


      span {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: var(--button-color);
        margin-right: 5px;
      }

      p {
        color: #1E1B39;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        margin-right: 5px;

        &:last-child {
          color: #9291A5;
          margin-right: 0;
        }
      }
    }
  }
}

.statistics_wrapper {
  padding: 32px 22px 35px 33px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0 2px 6px 0 rgba(13, 10, 44, 0.08);
  margin-top: 20px;

  .statistics_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h2 {
      color: #1E1B39;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    .statistics_header_right_block {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1280px) {
        &.salesReport {
          flex-direction: column-reverse;

          .calendar {
            margin-bottom: 20px;
          }
        }

      }

      .statistics_header_legend_wrapper {
        display: flex;
        align-items: center;

        .statistics_header_legend {
          display: flex;
          align-items: center;
          margin-right: 30px;
          cursor: pointer;
          transition: all 0.2s;

          &:hover, &.hide {
            opacity: 0.3;
          }

          span {
            width: 12px;
            height: 12px;
            border-radius: 10px;
            background: var(--button-color);
            margin-right: 10px;
          }

          p {
            color: var(--dark, #292929);
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }

      .calendar {
        display: flex;
        align-items: center;

        .selectWrapper {
          padding-top: 3px;
          margin-left: 10px;

          .fus__control {
            min-height: 40px;
          }
        }
      }
    }
  }
}


.apexcharts-tooltip-title {
  display: none;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  border: 0;

}


//.apexcharts-tooltip.apexcharts-theme-light {
//  background: black !important;
//}

.apexcharts-tooltip-series-group {
  background: black !important;
  color: #FFFFFF;
  height: 15px !important;
  border: 20px !important;
  padding: 10px !important;
}

.dashboard_loader_wrapper {
  position: relative;
  height: 450px;
}

.loader_dashboard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.toggle_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  .toggle_input {
    display: none;

    &:checked {
      & + .toggle_label {
        .toggle_back {
          background: #356966;

          .toggle_ellipse {
            left: 20px;
          }
        }
      }
    }

    &:disabled {
      & + .toggle_label {

        .toggle_back {
          background: #849291;
          border: 2px solid #849291;
          cursor: default;
        }
      }
    }
  }

  .toggle_label {
    display: flex;
    align-items: center;
    -webkit-touch-callout: none;
    user-select: none;
    padding: 0;

    svg {
      margin-right: 10px;
    }

    &:before {
      content: none;
      opacity: 0;
      position: absolute;
      transform: scale(0);
    }

    .toggle_text {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #292929;
    }

  }

  .toggle_back {
    width: 40px;
    height: 20px;
    background: #849291;
    border-radius: 10.5px;
    position: relative;
    transition: background-color 0.4s 0.2s;

    .toggle_ellipse {
      position: absolute;
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border-radius: 50%;
      top: 0;
      left: 1px;
      transition: all 0.4s;
      cursor: pointer;
    }
  }

}


//Dashboard end
